import React, { useState, useEffect } from 'react';
import { useQueryParam, NumberParam } from "use-query-params";
import 'assets/styles/pages/sidebar.scss';
import { Link, navigate } from 'gatsby';
import treeicon from 'assets/images/admin/tree-icon.png';
import breadcumbarrow from 'assets/images/admin/breadcumb-arrow-left.png';
import { useForm } from "react-hook-form";

import AdminService from 'services/admin/admin.service';
import { toast, ToastContainer } from 'react-toastify';
import { Modal, Spinner } from 'react-bootstrap';

export default function Admineditpage(props) {
  const [adminId] = useQueryParam("id", NumberParam);
  const adminApi = new AdminService();
  const [loading, setLoading] = useState([false]);
  const [firebaseId, setFirebaseId] = useState([]);
  const [emailAddress, setEmailAddress] = useState([]);
  const [adminName, setAdminName] = useState([]);
  const [statusType, setStatusType] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const statusChange = (e) => {
    setStatusType(e.target.value)
  }
  const onSubmit = async () => {
    if (adminName !== "" && statusType !== "" && firebaseId !== "" && emailAddress !== "") {
      setLoading(true)
      let formData = {
        "id": adminId,
        "name": adminName,
        "status": statusType,
        "emailAddress": emailAddress,
        // "firebaseId": firebaseId,
        "roles": [
          "ADMIN"
        ]
      }
      const stringData = JSON.stringify(formData);
      adminApi.updateAdmin(stringData).then(data => {
        if (data.status === 200) {
          toast.success("Admin Details Updated successfully");
          setLoading(false)
          setTimeout(function () {
            navigate("/admin/adminstatus")
          }, 3000)
        } else {
          toast.error("Something went wrong");
          setLoading(false)
        }
      }).catch(error => {
        setLoading(false)
        toast.error(error.response.data.message);
      })
    }
  }
  const loadSpeciesData = async () => {
    setLoading(true);
    await adminApi.getAdmin(adminId)
      .then(data => {
        if (data.status === 200) {
          setAdminName(data.data.name);
          setEmailAddress(data.data.emailAddress);
          setFirebaseId(data.data.firebaseId);
          setStatusType(data.data.status === "ACTIVE" ? 2 : 1);
          setLoading(false);
        } else {
          setLoading(false)
          toast.error("Something went wrong");
        }
      }).catch(error => {
        setLoading(false)
        if (error.response) {
          if (error.response.status === 401) {
            toast.error("JWT Token has Expired");
            navigate("/")
          } else {
            toast.error(error.response.data.message);
          }
        }
      });
  };
  const emailChange = (data) => {
    var emailData = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (data.match(emailData)) {
      setEmailAddress(data);
    }
    else {
      setEmailAddress(data)
    }
  }

  useEffect(() => {
    loadSpeciesData();
  }, []);
  return (
    <>
      <div className='admin-breadcumb-item'>
        <span>
          <img src={treeicon} alt='treeicon' />
        </span>
        <span>
          <img src={breadcumbarrow} alt='arrowicon' />
        </span>
        <h3> {adminName} </h3>
        {/* <span>
          <img src={breadcumbarrow} alt='arrowicon' />
        </span>
        <Link className='' to='/admin/adminstatus'>
          {' '}
          Back{' '}
        </Link> */}
      </div>

      <div className='white-iitiative-wrapper'>
        <div className=''>
          <h3 className='sub-heading-title'> EDIT ADMIN DETAILS </h3>
        </div>

        <div className='register-plantation'>
          <div className='container'>
            <div className='row'>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='fc-wrapper'>
                      <label htmlFor='name' className='form-label'>
                        Name
                      </label>
                      <input
                        type='text'
                        name='adminName'
                        className='form-control '
                        id='adminName'
                        onChange={(e) => setAdminName(e.target.value)}
                        value={adminName}
                      />
                      {adminName === "" ? <span className="form-error">
                        Name is Required
                      </span> : null}
                    </div>
                  </div>

                  <div className='col-md-6'>
                    <div className='fc-wrapper'>
                      <label htmlFor='email' className='form-label'>
                        Email Id
                      </label>
                      <input
                        type='email'
                        name='emailAddress'
                        className='form-control '
                        id='emailAddress'
                        readOnly
                        value={emailAddress}
                        onChange={(e) => { setEmailAddress(e.target.value) }}

                      />
                      {emailAddress === "" ? <span className="form-error">
                        Email Id is Required
                      </span> : null}
                    </div>
                  </div>

                  {/* <div className='col-md-6'>
                    <div className='fc-wrapper'>
                      <label htmlFor='firebaseId' className='form-label'>
                        Firebase Id
                      </label>
                      <input
                        type='text'
                        name='firebaseId'
                        className='form-control '
                        id='firebaseId'
                        value={firebaseId}
                        onChange={(e) => { setFirebaseId(e.target.value) }}

                      />
                      {firebaseId === "" ? <span className="form-error">
                        Firebase Id is Required
                      </span> : null}
                    </div>
                  </div> */}

                  <div className='col-md-6'>
                    <div className='fc-wrapper'>
                      <label htmlFor='planttype' className='form-label'>
                        Status
                      </label>
                      <select name='planttype' id='planttype' className='form-control' value={statusType} onChange={(e) => { statusChange(e) }}>
                        <option value='2'>Active</option>
                        <option value='1'> Inactive </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className='text-right'>
                  <Link to='/admin/adminstatus' className='btn btn-cancel mg-right10 margin-btm-10'>
                    Cancel
                  </Link>

                  <button className='btn btn-viewmore save-width'>
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal show={loading} centered className="modal-con">
        <div className="modal-con">
          <Spinner style={{ textAlign: "center" }} animation="border" />
        </div>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        newestOnTop={false}
        hideProgressBar
        closeOnClick
        rtl={false}
      />
    </>
  );
}
